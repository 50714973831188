<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<h5 class="mb-4">
								<strong>Control de gastos</strong>
							</h5>
						</div>
						<div class="col-md-6 text-right">
							<!-- <a-button class="btn btn-info" icon="plus" @click="onAddRecord">Registrar gasto</a-button> -->
							<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
						</div>
						<div class="col-md-4">
							<b>Rango de Fecha </b>
							<a-icon type="reload" @click="onResetDateFilter" :style="{ fontSize: '14px', color: 'red' }" />
							<a-range-picker style="width: 100%" @change="initModule" v-model="filters.defaultDate" :default-value="filters.defaultDate" :format="dateFormat" />
						</div>
						<div class="col-md-4">
							<b>Saldo disponible </b>
							<div class="fs20">{{ numeral(warehouseCashflowBalance).format('$0,0.00') }}</div>
						</div>
					</div>
					<a-table
						class="ant-table-striped pt10"
						:columns="columns"
						:dataSource="warehouseCashflowList"
						rowKey="id"
						size="small"
						:pagination="false"
						:rowClassName="(_record, index) => (_record.is_budget_allocation === 1 ? 'greenTR' : index % 2 === 1 ? 'table-striped' : '')"
					>
						<div slot="created_at" slot-scope="record">
							{{ moment(record.created_at).format('YYYY-MM-DD') }}
						</div>
						<div slot="income" slot-scope="record">
							{{ record.cash_flow_type == 'in' ? numeral(record.amount).format('$0,0.00') : '-' }}
						</div>
						<div slot="outbound" slot-scope="record">
							{{ record.cash_flow_type == 'out' ? numeral(record.amount).format('$0,0.00') : '-' }}
						</div>
						<div slot="description" slot-scope="record">
							<div class="text-left">
								{{ record.description }}
							</div>
						</div>
						<div slot="supplierName" slot-scope="record">
							{{ record.tradename | replaceIfLogicalFalse('-') }}
						</div>
						<div slot="purchase_order_id" slot-scope="record">
							<div v-if="record.purchase_order_id">
								<a :href="`/#/purchase-orders/${record.purchase_order_id}`" target="new">{{ record.purchase_order_id }}</a>
							</div>
							<div v-else>-</div>
						</div>
						<div slot="service_order_id" slot-scope="record">
							<div v-if="record.service_order_id">
								<a :href="`https://taller.rls.com.mx/admin/servicios/${record.service_order_id}`" target="new">{{ record.service_order_id }}</a>
							</div>
							<div v-else>-</div>
						</div>
					</a-table>
				</div>
			</div>

			<a-modal :visible="modal.visible" title="Registro de gastos en efectivo" :closable="true" width="65%">
				<template slot="footer">
					<a-button key="back" @click="onCloseModal"> Cancelar </a-button>
					<a-button key="submit" class="btn btn-success" @click="validateForm"> Guardar </a-button>
				</template>
				<div class="row">
					<div class="col-md-4">
						Proveedor
						<a-select v-model="modal.supplier_id" show-search allow-clear option-filter-prop="children" :filter-option="filterOption" :style="{ width: '100%' }">
							<a-select-option v-for="(element, index) in suppliersList" :key="index" :value="element.id">{{ element.tradename.toUpperCase() }}</a-select-option>
						</a-select>
						<small @click="getSuppliersList" class="pointer">Recarga</small>
					</div>
					<div class="col-md-2">
						Monto
						<a-input v-model="modal.amount" v-money="money" prefix="$" autocomplete="off" />
					</div>
					<div class="col-md-3">
						ODC
						<a-input v-model="modal.purchase_order_id" v-mask="'#####'" autocomplete="off" />
					</div>
					<div class="col-md-3">
						ODS
						<a-input v-model="modal.service_order_id" v-mask="'#####'" autocomplete="off" />
					</div>
					<div class="col-md-12 pt10">
						Concepto
						<a-input v-model="modal.description" autocomplete="off" />
					</div>
				</div>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { VMoney } from 'v-money'
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'
const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'warehouseExpenseControl',
	directives: {
		money: VMoney,
		mask,
	},
	components: {},
	computed: {
		...mapGetters('warehouseCashflow', ['spinnerLoader', 'spinnerLoaderLabel', 'filters', 'warehouseCashflowList', 'warehouseCashflowBalance']),
		...mapGetters('suppliers', ['suppliersList']),
		...mapGetters('user', ['user']),
	},
	data() {
		return {
			form: this.$form.createForm(this),
			dateFormat,
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			columns: [
				{
					title: 'Fecha',
					scopedSlots: { customRender: 'created_at' },
					align: 'center',
				},
				{
					title: 'Ingreso',
					scopedSlots: { customRender: 'income' },
					align: 'right',
				},
				{
					title: 'Egreso',
					scopedSlots: { customRender: 'outbound' },
					align: 'right',
				},
				{
					title: 'Concepto',
					scopedSlots: { customRender: 'description' },
					align: 'center',
				},
				{
					title: 'Proveedor',
					scopedSlots: { customRender: 'supplierName' },
					align: 'center',
					width: '250px',
				},
				{
					title: 'ODC',
					scopedSlots: { customRender: 'purchase_order_id' },
					align: 'center',
				},
				{
					title: 'ODS',
					scopedSlots: { customRender: 'service_order_id' },
					align: 'center',
				},
			],
			modal: {
				visible: false,
				supplier_id: '',
				amount: '',
				purchase_order_id: '',
				service_order_id: '',
				description: '',
			},
		}
	},
	mounted() {
		this.initModule()
		if (!this.suppliersList.length) {
			this.getSuppliersList()
		}
	},
	destroyed() {
		this.onResetDateFilter()
	},
	methods: {
		moment,
		numeral,
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		onResetDateFilter() {
			this.$store.commit('warehouseCashflow/SET_STATE', {
				filters: {
					...this.filters,
					defaultDate: [moment().startOf('month').format(dateFormat), moment().startOf('month').add(14, 'd').format(dateFormat)],
				},
			})
			this.initModule()
		},
		initModule() {
			let payload = {
				startDate: moment(this.filters.defaultDate[0]).format(dateFormat),
				endDate: moment(this.filters.defaultDate[1]).format(dateFormat),
			}
			this.$store.dispatch('warehouseCashflow/GET_ALL', payload)
		},
		onAddRecord() {
			this.modal.visible = true
		},
		onCloseModal() {
			this.modal = {
				visible: false,
				supplier_id: '',
				amount: '',
				purchase_order_id: '',
				service_order_id: '',
				description: '',
			}
		},
		getSuppliersList() {
			this.$store.dispatch('suppliers/GET_NO_REDIRECT')
		},
		validateForm() {
			//
			let errors = []
			if (this.modal.supplier_id == '') {
				errors.push('- Selecciona proveedor')
			}
			if (numeral(this.modal.amount).value() < 1) {
				errors.push('- Ingresa monto del gasto')
			}
			if (this.modal.description == '') {
				errors.push('- Ingresa concepto del gasto')
			}

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			}
			let payload = {
				...this.modal,
			}
			this.$store.dispatch('warehouseCashflow/CREATE', payload)
		},
	},
	watch: {
		warehouseCashflowBalance(newValue) {
			if (newValue) {
				this.onCloseModal()
			}
		},
	},
}
</script>