import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getDashboardReport(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/direction', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getTechnicProfit(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/direction/technic-profit', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getCashBalance(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/direction/get-cash-balance', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getLoanPurchaseOrders(company) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/direction/loan-purchase-orders/${company}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getProductivity(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/direction/productivity', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getGeneralFinances(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/direction/general-finances', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function newDirectionGraphs(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/direction/new-direction-graphs', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
