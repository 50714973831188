<template>
	<div>
		<!-- <button @click="initModule()">initModule()</button> -->
		<div class="row">
			<div class="col-md-2">
				<h2 class="font-size-18 font-weight-bold mb-1 text-dark">Segmentación</h2>
				<a-select v-model="segment" :style="{ width: '100%' }" @change="initModule">
					<a-select-option value="q"> Quincenal </a-select-option>
					<a-select-option value="m"> Mensual </a-select-option>
				</a-select>
			</div>
			<div class="col-md-10" v-if="false">
				<div class="text-primary font-weight-bold font-size-24 text-right">
					Meta semestral ${{ dashboardReport.semestralGoal / 1000000 }}K
					<div class="font-size-18" style="color: #f5222e">~ ${{ numeral(dashboardReport.average).format('0,0') }}</div>
				</div>
			</div>
		</div>

		<div class="mb-3 pt10" v-if="dashboardReport.granTotalGroup > 0">
			<vue-c3 :handler="bar" class="height-300"></vue-c3>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import _ from 'lodash'
import moment from 'moment-timezone'
import utilities from '@/services/utilities'

//
moment.tz.setDefault('America/Mexico_City')

export default {
	name: 'directionDashboard',
	components: {
		VueC3,
	},
	props: {
		semester: {
			type: String,
			defaut: '1',
		},
		year: {
			type: Number,
			defaut: moment().year(),
		},
		branch_office_id: {
			type: String,
			defaut: '1',
		},
		company: {
			type: String,
			defaut: 's2r',
		},
	},
	computed: {
		...mapGetters('user', ['user']),
		barOptions() {
			return {
				data: {
					json: utilities.objectValidate(this.dashboardReport, 'groupData.labels', []).map((e, i) => {
						if (this.company == 's2r') {
							return {
								name: e,
								Boutique: this.dashboardReport.groupData.series[1][i].value,
								Refacciones: this.dashboardReport.groupData.series[0][i].value,
								Total: this.dashboardReport.groupData.series[2][i].value,
							}
						} else {
							return {
								name: e,
								Total: this.dashboardReport.groupData.series[0][i].value,
							}
						}
					}),
					keys: {
						x: 'name',
						value: this.company == 's2r' ? ['Refacciones', 'Boutique', 'Total'] : ['Total'],
					},
					types: {
						Refacciones: 'bar',
						Boutique: 'bar',
						Total: 'bar',
					},
					groups: this.company == 's2r' ? [['Refacciones', 'Boutique']] : false, // Configura las barras apiladas
				},
				bar: {
					width: {
						max: 20,
					},
				},
				axis: {
					x: {
						type: 'category',
					},
					y: {
						show: !1,
					},
				},
				tooltip: {
					format: {
						value: function (value) {
							return numeral(value).format('$0,0.00')
						},
					},
				},
				grid: {
					y: {
						show: true,
					},
					x: {
						show: false,
					},
				},
				color: {
					pattern: this.company == 's2r' ? ['#cff4fc', '#42baf9', 'green'] : [this.company == 'rls' ? '#2b2f32' : '#722ed1'],
				},
			}
		},
	},
	data() {
		return {
			segment: 'q',
			bar: new Vue(),
			colors: {
				primary: '#01a8fe',
				def: '#acb7bf',
				success: '#46be8a',
				danger: '#fb434a',
			},
			dashboardReport: {},
		}
	},
	destroyed() {
		this.dashboardReport.granTotalGroup = 0
	},
	mounted() {
		this.initModule()
	},
	methods: {
		numeral,
		moment,
		initModule() {
			this.dashboardReport.granTotalGroup = 0
			this.$store
				.dispatch('direction/GET_NEW_DIRECTION_GRAPHS', {
					company: this.company,
					year: this.year,
					semester: Number(this.semester),
					segment: this.segment,
					branch_office_id: this.branch_office_id,
				})
				.then((response) => {
					// console.log('GET_NEW_DIRECTION_GRAPHS::response-->', response)
					this.dashboardReport = response.data
				})
		},
	},
	watch: {
		dashboardReport(newData) {
			if (utilities.objectValidate(newData, 'granTotalGroup', 0) > 0) {
				setTimeout(() => {
					this.bar.$emit('init', this.barOptions)
				}, 500)
			}
		},
	},
}
</script>
