<template>
	<div>
		<!-- <button @click="initModule()">initModule()</button> -->
		<div class="row">
			<div class="col-md-3">
				<b>Ubicación</b>
				<a-select v-model="branch_office_id" :style="{ width: '100%' }" @change="initModule">
					<a-select-option value=""> Todas </a-select-option>
					<a-select-option v-for="(element, key) in branchOfficesList" :key="key" :value="element.id"> {{ element.branch_office_name }} </a-select-option>
				</a-select>
			</div>
			<div class="col-md-3">
				<b>Año</b>
				<a-select v-model="year" :style="{ width: '100%' }" @change="initModule">
					<a-select-option value="2023"> 2023 </a-select-option>
					<a-select-option value="2024"> 2024 </a-select-option>
					<a-select-option value="2025"> 2025 </a-select-option>
				</a-select>
			</div>
			<div class="col-md-3">
				<b>Semestre</b>
				<a-select v-model="semester" :style="{ width: '100%' }" @change="initModule">
					<a-select-option value="1"> Primer semestre </a-select-option>
					<a-select-option value="2"> Segundo semestre </a-select-option>
				</a-select>
			</div>
		</div>
		<div class="row pt20" v-if="loadedData">
			<div class="col-lg-4">
				<div class="card">
					<div class="card-body p0">
						<div class="card-title text-center bgGreen pointer" @click="getGraphs('s2r')" style="padding: 20px 25px; margin: 0">
							<div class="font-size-21">S2R</div>
						</div>
						<div style="padding: 10px 25px; background-color: #f0fcfe">
							<div v-if="branch_office_id == ''">
								<div v-for="(element, key) in branchOfficesList" :key="key">
									<b>{{ element.branch_office_name }}</b>
									<div style="padding-left: 10px">
										<div><b>Total:</b> {{ numeral(localData?.pacific[element.id]?.boutique + localData?.pacific[element.id]?.spareparts).format('$0,0.00') }}</div>
										<div><b>Boutique:</b> {{ numeral(localData?.pacific[element.id]?.boutique).format('$0,0.00') }}</div>
										<div><b>Refacciones:</b> {{ numeral(localData?.pacific[element.id]?.spareparts).format('$0,0.00') }}</div>
									</div>
									<hr v-if="key + 1 < branchOfficesList.length" />
								</div>
							</div>
							<div style="padding: 10px 25px; background-color: #f0fcfe" v-else>
								<div><b>Total:</b> {{ numeral(localData?.pacific[branch_office_id]?.boutique + localData?.pacific[branch_office_id]?.spareparts).format('$0,0.00') }}</div>
								<div><b>Boutique:</b> {{ numeral(localData?.pacific[branch_office_id]?.boutique).format('$0,0.00') }}</div>
								<div><b>Refacciones:</b> {{ numeral(localData?.pacific[branch_office_id]?.spareparts).format('$0,0.00') }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="card">
					<div class="card-body p0">
						<div class="card-title text-center bgRls pointer" @click="getGraphs('rls')" style="padding: 20px 25px; margin: 0">
							<div class="font-size-21">RLS</div>
						</div>
						<div style="padding: 10px 25px; background-color: #f5f5f6" v-if="branch_office_id == ''">
							<div v-for="(element, key) in branchOfficesList" :key="key">
								<b>{{ element.branch_office_name }}</b>
								<div style="padding-left: 10px">
									<div><b>Total:</b> {{ numeral(localData?.rls[element.id]).format('$0,0.00') }}</div>
								</div>
								<hr v-if="key + 1 < branchOfficesList.length" />
							</div>
						</div>
						<div style="padding: 10px 25px; background-color: #f5f5f6" v-else>
							<div><b>Total:</b> {{ numeral(localData?.rls[branch_office_id]).format('$0,0.00') }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="card">
					<div class="card-body p0">
						<div class="card-title text-center bg-purple panel pointer" @click="getGraphs('panel')" style="padding: 20px 25px; margin: 0">
							<div class="font-size-21">Otros Ingresos</div>
						</div>
						<div style="padding: 10px 25px; background-color: #fbf6ff" v-if="branch_office_id == ''">
							<div v-for="(element, key) in branchOfficesList" :key="key">
								<div v-if="element.allow_cash">
									<b>{{ element.branch_office_name }}</b>
									<div style="padding-left: 10px">
										<div><b>Total:</b> {{ numeral(localData?.panel[element.id]).format('$0,0.00') }}</div>
									</div>
									<hr v-if="key + 1 < branchOfficesList.length" />
								</div>
							</div>
						</div>
						<div style="padding: 10px 25px; background-color: #fbf6ff" v-else>
							<div><b>Total:</b> {{ numeral(localData?.panel[branch_office_id]).format('$0,0.00') }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<a-modal :visible="modalVisible" title="Gráfica de Ingresos" :closable="false" width="80%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal"> Cancelar </a-button>
			</template>
			<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
				<directionGraphs v-if="modalVisible" ref="directionGraphs" :semester="semester" :year="year" :branch_office_id="branch_office_id" :company="company" />
			</a-spin>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import utilities from '@/services/utilities'
import directionGraphs from './graphs.vue'

moment.tz.setDefault('America/Mexico_City')

export default {
	name: 'directionFinancesDashboard',
	components: {
		directionGraphs,
	},
	computed: {
		...mapGetters('branchOffices', ['branchOfficesList']),
		...mapGetters('direction', ['spinnerLoader', 'spinnerLoaderLabel']),
	},
	data() {
		return {
			semester: moment().month() < 6 ? '1' : '2',
			year: moment().year(),
			branch_office_id: '',
			localData: {},
			loadedData: false,
			modalVisible: false,
			company: '',
		}
	},
	beforeMount() {
		if (this.branchOfficesList.length == 0) {
			this.$store.dispatch('branchOffices/GET')
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		numeral,
		initModule() {
			this.loadedData = false
			let payload = {
				year: this.year,
				semester: Number(this.semester),
				segment: this.segment,
				branch_office_id: this.branch_office_id,
			}

			this.$store.dispatch('direction/GET_GENERAL_FINANCES', payload).then((response) => {
				this.localData = utilities.objectValidate(response, 'data', {})
				setTimeout(() => {
					this.loadedData = true
				}, 10)
			})
		},
		getGraphs(company) {
			this.company = company
			this.modalVisible = true
		},
		onCloseModal() {
			this.$refs.directionGraphs.granTotalGroup = 0
			this.modalVisible = false
		},
	},
}
</script>
