<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-info" icon="plus" v-if="allowComplaints" @click="registerComplaints"> Nueva queja </a-button>
					<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					<a-table :columns="columns" :dataSource="complaintsList" rowKey="id" :pagination="false">
						<div slot="link" slot-scope="record">
							<a :href="record.link" target="_blank">
								{{ record.link }}
							</a>
							<div class="mt5">
								<a :href="`https://taller.rls.com.mx/admin/servicios/${record.service_order}`" target="_blank">
									<a-tag v-if="record.service_order" color="purple">ODS {{ record.service_order }}</a-tag>
								</a>
							</div>
						</div>
						<div slot="action" slot-scope="record">
							<a-button class="ml7 btn btn-success" size="small" icon="check" @click="onResolveComplaint(record.id)" />
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<!-- Registro de quejas -->
		<a-modal :visible="modal.complaints.visible" title="Registro de quejas" :footer="false" :closable="true" @cancel="onCloseModal('complaints')" width="50%">
			<a-form :form="formComplaints" @submit="handleSubmitComplaints">
				<div class="row">
					<div class="col-md-3">
						<a-form-item label="# ODS">
							<a-input autocomplete="off" v-mask="'####'" v-decorator="['service_order']" />
						</a-form-item>
					</div>
					<div class="col-md-9">
						<a-form-item label="Link de conversación en TRENGO">
							<a-input autocomplete="off" v-decorator="['link']" />
						</a-form-item>
					</div>
					<div class="col-md-12">
						<a-form-item label="Comentarios">
							<a-textarea
								autocomplete="off"
								v-decorator="[
									'comments',
									{
										rules: [
											{
												required: true,
												message: 'Debes explicar el motivo de la queja',
											},
										],
									},
								]"
							/>
						</a-form-item>
					</div>
				</div>
				<div class="row pt20">
					<div class="col-md-4 text-left">
						<a-button class="btn btn-danger" @click="onCloseModal('complaints')">Cancelar</a-button>
					</div>
					<div class="col-md-8 text-right">
						<a-button icon="save" class="btn btn-success" htmlType="submit">Guardar</a-button>
					</div>
				</div>
			</a-form>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import { mask } from 'vue-the-mask'

export default {
	name: 'complaintsComponent',
	components: {},
	directives: {
		mask,
	},
	computed: {
		...mapGetters('complaints', ['spinnerLoader', 'spinnerLoaderLabel', 'complaintsList']),
		...mapGetters('user', ['user']),
		allowComplaints() {
			// Ismael - 1
			// Zavala - 4
			// Pozos - 11
			// Brenda - 39
			let allowedUserPermition = [1, 4, 11, 39]
			return !!allowedUserPermition.includes(this.user.user_id)
		},
	},
	data() {
		return {
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					align: 'center',
					width: '10%',
				},
				{
					title: 'Link Trengo / ODS',
					scopedSlots: { customRender: 'link' },
				},
				{
					title: 'Comentario',
					dataIndex: 'comments',
					key: 'comments',
				},
				{
					title: 'Fecha',
					dataIndex: 'created_at',
					align: 'center',
					width: '10%',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '15%',
				},
			],
			modal: {
				complaints: {
					visible: false,
				},
			},
			formComplaints: this.$form.createForm(this),
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		initModule() {
			this.$store.dispatch('complaints/GET')
		},
		onCloseModal(type) {
			this.modal[type].visible = false
			this.formComplaints.resetFields()
		},
		onResolveComplaint(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas marcar como atendida la queja?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('complaints/RESOLVE', { id, requireReload: true })
				}
			})
		},
		registerComplaints() {
			this.modal.complaints.visible = true
		},
		handleSubmitComplaints(e) {
			e.preventDefault()
			this.formComplaints.validateFields((err, values) => {
				if (!err) {
					//
					Swal.fire({
						title: 'Atención',
						html: '¿Deseas confirmar el registro de queja?',
						icon: 'warning',
						showCancelButton: true,
						reverseButtons: true,
						confirmButtonColor: '#41b883',
						confirmButtonText: 'Sí, continuar',
						cancelButtonColor: '#f5222e',
						cancelButtonText: 'No, cancelar',
					}).then((result) => {
						if (result.isConfirmed) {
							//
							this.$store.dispatch('complaints/CREATE', { payload: values, requireReload: false }).then(() => {
								//
								this.onCloseModal('complaints')
							})
						}
					})
				}
			})
		},
	},
}
</script>
