export default Object.freeze({
	1: {
		title: 'MATRIZ',
		color: '#fff',
		backgroundColor: '#066f71',
	},
	2: {
		title: 'CENTRO',
		color: '#fff',
		backgroundColor: '#066899',
	},
	3: {
		title: 'CHALCO',
		color: '#737373',
		backgroundColor: '#f4ba8e',
	},
})
